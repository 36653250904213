import { ModuleLoadUtil } from './moduleLoadUtil'

// auto load bundles
const script = document.currentScript
if (script && script.getAttribute('src')?.includes('/static/js/loader.js')) {
  const host = script.getAttribute('src')
  if (host) {
    ModuleLoadUtil.loadModule(host)
  }
}
